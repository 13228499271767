import { defineStore } from "pinia";
// import { ref, toRefs } from "vue";

export const useOnBoardingStore = defineStore(
  "onboarding",
  () => {
    const account_detail = ref<{
      phone: string;
      nik: string;
      ktp:
        | (File & {
            name: string;
            size: number;
            type: string;
            fullPath: string;
            url: string;
          })
        | null;
    }>({
      phone: "",
      nik: "",
      ktp: null,
    });
    const bank_detail = ref<{
      bank_name: string;
      account_number: string;
      name_of_account_holder: string;
    }>({
      bank_name: "",
      account_number: "",
      name_of_account_holder: "",
    });
    const business_detail = ref<{
      business_type: string;
      npwp: string;
      nib: string | null | undefined;
    }>({
      business_type: "",
      npwp: "",
      nib: "",
    });

    function $reset() {
      account_detail.value = {
        phone: "",
        nik: "",
        ktp: null,
      };
      bank_detail.value = {
        bank_name: "",
        account_number: "",
        name_of_account_holder: "",
      };
      business_detail.value = {
        business_type: "",
        npwp: "",
        nib: "",
      };
    }

    function set(params: {
      account_detail?: typeof account_detail.value;
      bank_detail?: typeof bank_detail.value;
      business_detail?: typeof business_detail.value;
    }) {
      console.log("set", params);
      const myStore = useOnBoardingStore();
      const states: any = toRefs(myStore.$state);
      const keys = Object.keys(params);

      keys.forEach((key: string) => {
        states[key].value = params[key as keyof typeof params];
      });
    }

    return {
      account_detail,
      bank_detail,
      business_detail,
      $reset,
      set,
    };
  },
  {
    persist: true,
  },
);
